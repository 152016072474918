











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "Content",
})
export default class Content extends Vue {
  @Prop(Boolean)
  leftLarge!: boolean;

  @Prop(Boolean)
  masterDetail!: boolean;

  @Prop(Boolean)
  hideRightBar!: boolean;

  @Prop(Boolean)
  leftFull!: boolean;
}
